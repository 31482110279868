import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import configs from "../../utils/configs";
import { CreateRoomButton } from "./CreateRoomButton";
import { PWAButton } from "./PWAButton";
import { useFavoriteRooms } from "./useFavoriteRooms";
import { usePublicRooms } from "./usePublicRooms";
import styles from "./HomePage.scss";
import { AuthContext } from "../auth/AuthContext";
import { createAndRedirectToNewHub } from "../../utils/phoenix-utils";
import { MediaGrid } from "../room/MediaGrid";
import { MediaTile } from "../room/MediaTiles";
import { PageContainer } from "../layout/PageContainer";
import { scaledThumbnailUrlFor } from "../../utils/media-url-utils";
import { Column } from "../layout/Column";
import { Button } from "../input/Button";
import { Container } from "../layout/Container";
import { SocialBar } from "../home/SocialBar";
import { SignInButton } from "./SignInButton";
import maskEmail from "../../utils/mask-email";
import { ReactComponent as HmcLogo } from "../icons/HmcLogo.svg";
import timelineimg from "../../assets/images/timeline.png";
import avatar from "../../assets/images/vip_PROTOTYPE_static_3.png";
//import clouds from "../../assets/images/roadmap-background-clouds.svg";
const clouds = new URL( "../../assets/images/roadmap-background-clouds.svg", import.meta.url);

export function HomePage() {
  const auth = useContext(AuthContext);
  const intl = useIntl();

  const { results: favoriteRooms } = useFavoriteRooms();
  const { results: publicRooms } = usePublicRooms();

  const sortedFavoriteRooms = Array.from(favoriteRooms).sort((a, b) => b.member_count - a.member_count);
  const sortedPublicRooms = Array.from(publicRooms).sort((a, b) => b.member_count - a.member_count);
  const wrapInBold = chunk => <b>{chunk}</b>;
  const isHmc = configs.feature("show_cloud");
  
  useEffect(() => {
    const qs = new URLSearchParams(location.search);

    // Support legacy sign in urls.
    if (qs.has("wallet_sign_in")) {
      //window.nearWallet.requestSignIn(window.nearConfig.contractName, "Nearhub");
    } else if (qs.has("sign_in")) {
      const redirectUrl = new URL("/signin", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    } else if (qs.has("auth_topic")) {
      const redirectUrl = new URL("/verify", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    }

    if (qs.has("new")) {
      createAndRedirectToNewHub(null, null, true);
    }

    fetch("https://api.npoint.io/1f236a27049f6f36c812", {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    }).then(function(response) {
      return response.json();
    }).then((data) => setNewsItems(data));    

  }, []);

  const [newsItems, setNewsItems] = useState([]);

  const canCreateRooms = !configs.feature("disable_room_creation") || auth.isAdmin;
  const email = auth.email?.replace("-auth@parse.nearhub.club", "");

  const featuredRoom = sortedPublicRooms.pop();  

  return (
    <PageContainer className={styles.homePage}>
      <Container>
        <div className={styles.hero}>
          <div className={styles.logoContainer}>
            {isHmc ? <HmcLogo /> : <img alt={configs.translation("app-name")} src={configs.image("logo")} />}
            {auth.isSignedIn ? (
              <div className={styles.signInContainer}>
                <span>
                  <FormattedMessage
                    id="header.signed-in-as"
                    defaultMessage="{email}"
                    values={{ email: maskEmail(email) }}
                  />
                </span>
                <a href="#" onClick={auth.signOut} className={styles.mobileSignOut}>
                  <FormattedMessage id="header.sign-out" defaultMessage="Sign Out" />
                </a>
              </div>
            ) : (
              <SignInButton mobile />
            )}
          </div>
          <div className={styles.appInfo}>
            <h1>
              <span>Privacy</span> is the new ‘<span>cool</span>’
            </h1>
            <p>Now you can enjoy the Metaverse and be in complete control of your data.</p>
            <p className="highlight">Beta mainnet live Now!</p>
            {/*
            <div className={styles.appDescription}>{configs.translation("app-description")}</div>
            {canCreateRooms && <CreateRoomButton />}
            <PWAButton />
            */}
          </div>
          <img src={timelineimg} alt="Beta mainnet live now" />
        </div>
      </Container>
      {newsItems.length > 0 && (
        <div style={{maxWidth:'1140px', margin: '0 auto'}}>
          <Column grow padding className={styles.rooms} style={{ margin: '0 20px' }}>
            {
              newsItems.map((newsItem, ind) => <div key={'newsItem'+ind} className={styles['news-item']} dangerouslySetInnerHTML={{__html: newsItem.html }} />)
            }
          </Column>
        </div>
      )}
      {configs.feature("show_feature_panels") && (
        <Container className={classNames(styles.features, styles.colLg, styles.centerLg)}>
          <Column padding gap="xl" className={styles.card}>
            <img src={configs.image("landing_rooms_thumb")} />
            <h3>
              <FormattedMessage id="home-page.rooms-title" defaultMessage="Instantly create rooms" />
            </h3>
            <p>
              <FormattedMessage
                id="home-page.rooms-blurb"
                defaultMessage="Share virtual spaces with your friends, co-workers, and communities. When you create a room with Hubs, you’ll have a private virtual meeting space that you can instantly share <b>- no downloads or VR headset necessary.</b>"
                values={{ b: wrapInBold }}
              />
            </p>
          </Column>
          <Column padding gap="xl" className={styles.card}>
            <img src={configs.image("landing_communicate_thumb")} />
            <h3>
              <FormattedMessage id="home-page.communicate-title" defaultMessage="Communicate and Collaborate" />
            </h3>
            <p>
              <FormattedMessage
                id="home-page.communicate-blurb"
                defaultMessage="Choose an avatar to represent you, put on your headphones, and jump right in. Hubs makes it easy to stay connected with voice and text chat to other people in your private room."
              />
            </p>
          </Column>
          <Column padding gap="xl" className={styles.card}>
            <img src={configs.image("landing_media_thumb")} />
            <h3>
              <FormattedMessage id="home-page.media-title" defaultMessage="An easier way to share media" />
            </h3>
            <p>
              <FormattedMessage
                id="home-page.media-blurb"
                defaultMessage="Share content with others in your room by dragging and dropping photos, videos, PDF files, links, and 3D models into your space."
              />
            </p>
          </Column>
        </Container>
      )}
      {(featuredRoom || sortedPublicRooms.length > 0) && (
        <Container className={styles.roomsContainer} id="rooms">
          <h3 className={styles.roomsHeading}>
            <FormattedMessage id="home-page.public--rooms" defaultMessage="NEAR Hub Rooms" />
          </h3>
          <Column grow padding className={styles.rooms}>
            <MediaGrid center className={styles.featuredRoom}>
              {
                <>
                  <MediaTile
                    key={featuredRoom.id}
                    entry={featuredRoom}
                    processThumbnailUrl={(entry, width, height) =>
                      scaledThumbnailUrlFor(entry.images.preview.url, width, height)
                    }
                  />
                  <p>
                    NEAR Hub Online takes you on an immersive journey through the NEAR Ecosystem<br />
                    <br />
                    Host your events Virtually<br />
                    <br />
                    The perfect place to meet like minds.<br />
                    <br />
                    Learn about NEAR Protocol, DeFi, DAOs, Gaming, NFTs and so much more.
                  </p>
                </>
              }
            </MediaGrid>
            {sortedPublicRooms.length > 0 ? (
              <MediaGrid center>
                {sortedPublicRooms.map(room => {
                  return (
                    <MediaTile
                      key={room.id}
                      entry={room}
                      processThumbnailUrl={(entry, width, height) =>
                        scaledThumbnailUrlFor(entry.images.preview.url, width, height)
                      }
                    />
                  );
                })}
              </MediaGrid>
            ) : (
              <></>
            )}
            {canCreateRooms && <CreateRoomButton />}
          </Column>
        </Container>
      )}
      {sortedFavoriteRooms.length > 0 && (
        <Container className={styles.roomsContainer}>
          <h3 className={styles.roomsHeading}>
            <FormattedMessage id="home-page.favorite-rooms" defaultMessage="Favorite Rooms" />
          </h3>
          <Column grow padding className={styles.rooms}>
            <MediaGrid center>
              {sortedFavoriteRooms.map(room => {
                return (
                  <MediaTile
                    key={room.id}
                    entry={room}
                    processThumbnailUrl={(entry, width, height) =>
                      scaledThumbnailUrlFor(entry.images.preview.url, width, height)
                    }
                  />
                );
              })}
            </MediaGrid>
          </Column>
        </Container>
      )}
      <Container>
        <Column center grow>
          <Button thin preset="landing" as="a" href="/link">
            <FormattedMessage id="home-page.have-code" defaultMessage="Have a room code?" />
          </Button>
        </Column>
      </Container>
      {isHmc ? (
        <Column center>
          <SocialBar />
        </Column>
      ) : null}
      <Container className={styles.rarity} id="rarity">
        <Column>
          <h2>
            10,000 Avatars...<br />How rare is yours?
          </h2>
          <p>
            Everyone at NEAR Hub Has access to use the base model of HRMS-v001.<br />
            <br />
            For those of you that would like something extra special.<br />
            <br />
            We are coming out with a collection of Generative HRMS Avatars in NFTs! COMING VERY SOON!
          </p>
        </Column>
        <Column center>
          <img src={avatar} alt="10,000 Avatars" />
        </Column>
      </Container>
      <div id="tutorial" className={styles.tutorial}>
        <Container>
          <Column style={{ flexShrink: 1 }}>
            <h2>How to?</h2>
            <h4>MOVE MOBILE:</h4>
            <p>
              Pinch/Pull to move forward. Touch the screen and move your finger to look around. (*To add onscreen
              joystick controls go to the menu / Preferences / Controls )
            </p>
            <h4>MOVE COMPUTER:</h4>
            <p>
              Arrow keys to move around. Click and hold the mouse to look around. Hold the Shift Key to move faster. In
              special rooms pressing the `G` key enables flight!
            </p>
            <h4>USE THE CAMERA:</h4>
            <ul>
              <li>Press the `C` key to make visibel and hide the camera.</li>
              <li>Use the view finder to line up your image.</li>
              <li>Press the `Spacebar` and hover over the camera for more options.</li>
              <li>
                Click and hold the rotate button to change the camera angle. Hold the `Shift` key to fine tune the
                angle.
              </li>
              <li>
                Once the frame is lined up. Click either the camera button on the top of the camera for a photo. or the
                Video button on the bottom of the camera to take a Metaverse video.
              </li>
              <li>
                Once the photo / video pops out. Hover over it with your mouse to Tweet it or to MINT it as an NFT to
                the NEAR Blockchain.
              </li>
            </ul>
          </Column>
        </Container>
      </div>
      <div style={{ background: "rgba(226, 245, 255, 0.5)", backgroundImage: `url(${clouds})` }} id="roadmap">
        <Container className={styles.roadmapContainer}>
          <Column>
            <h2 style={{maxWidth: '90vw'}}>NEAR Hub Online Roadmap</h2>
            <ul className="roadmap__list">
              <li className="roadmap__item">
                <h3 className="roadmap__item-title">Q4 2021</h3>
                <h3 className="roadmap__item-text">Launch NEAR Hub with NEAR testnet wallet login - Complete</h3>
                <h3 className="roadmap__item-text">Create Cheddar Farm - complete</h3>
                <h3 className="roadmap__item-text">Create Asro Dao Community Spaceship - complete</h3>
                <h3 className="roadmap__item-text">Twitch Integration - complete</h3>
                <h3 className="roadmap__item-text">NEAR Hub Discord Bot - complete</h3>
                <h3 className="roadmap__item-text">Create Custom Avatar Character - complete</h3>
                <h3 className="roadmap__item-text">NEAR Hub NFT Marketplace - in progress</h3>
                <h3 className="roadmap__item-text">Communication System upgrade - in progress</h3>
                <h3 className="roadmap__item-text">Twitter Integration - in progress</h3>
                <h3 className="roadmap__item-text">360 video Virtual Reality Experiences - Coming Soon</h3>
              </li>
              <li className="roadmap__item">
                <h3 className="roadmap__item-title">Q1 2022</h3>
                <h3 className="roadmap__item-text">Add in world game mechanics</h3>
                <h3 className="roadmap__item-text">Human Guild Arcade</h3>
                <h3 className="roadmap__item-text">Token Gated Rooms</h3>
                <h3 className="roadmap__item-text">In World DAO voting</h3>
                <h3 className="roadmap__item-text">Peer to Peer token transfers</h3>
              </li>
              <li className="roadmap__item">
                <h3 className="roadmap__item-title">Q2 2022</h3>
                <h3 className="roadmap__item-text">UI Improvements</h3>
                <h3 className="roadmap__item-text">Upgrade Avatars</h3>
                <h3 className="roadmap__item-text">NEAR Hub Auctions</h3>
                <h3 className="roadmap__item-text">Deploy Beta node on IPFS</h3>
              </li>
              <li className="roadmap__item">
                <h3 className="roadmap__item-title">Q3 2022</h3>
                <h3 className="roadmap__item-text">IDO Launch</h3>
                <h3 className="roadmap__item-text">NEAR Hub Park</h3>
                <h3 className="roadmap__item-text">NEAR Hub Community Loyalty Program Launch</h3>
                <h3 className="roadmap__item-text">Deploy Alpha node on IPFS</h3>
              </li>
              <li className="roadmap__item">
                <h3 className="roadmap__item-title">Q4 2022</h3>
                <h3 className="roadmap__item-text">Public Token Launch</h3>
                <h3 className="roadmap__item-text">Launch of Community Mining Nodes to support the network</h3>
              </li>
            </ul>
          </Column>
        </Container>
      </div>
      <Container className={styles.faq} id="faq">
        <Column>
          <h2>Frequently Asked Questions</h2>
          <h5>What’s an NFT?</h5>
          <p>
            A non-fungible token is a unique and non-interchangeable unit of data stored on a digital ledger. NFTs can
            be associated with easily-reproducible items such as photos, videos, audio, and other types of digital files
            as unique items, and use blockchain technology to give the NFT a public proof of ownership.
          </p>

          <h5>Where does my NFT go after I purchase it?</h5>
          <p>
            When you mint an NFT it will be stored in your NEAR wallet. (wallet.near.org or testnet.wallet.near.org
            depending which network you are connected to.) Once in your wallet go to the `Collectibles` page. There you
            will find all of your NFTs. They can also be viewed in the NEAR (N) menu inside NEAR Hub.
          </p>

          <h5>What should I do if I have audio issues?</h5>
          <h6>* Make sure you have a steady internet signal *</h6>
          <p>
            <b>Microphone issues:</b>
            <br />By default when you enter the room your microphone will be muted. To turn mute off and on click the
            Mic button at the bottom center of the screen when inside a room.
          </p>
          <p>
            If mute is off and others in the room can not hear you still. Click the `...Menu` at the bottom right of the
            screen. then Click Preferences. Under the Audio properties, Turn your soundcard to `none` then back on to
            your desired device. Make sure your mic is not muted again.
          </p>
          <p>
            If Audio issues persist make sure the following settings are correct.<br />
            (1) Browser has Mic permissions turned to allow.<br />
            (2)microphonecable is not muted.<br />
            (3) Audio drivers are upto date.<br />
            (4) Microphone volume is turned up in your OS
          </p>
          <h6>Try refreshing your browser.</h6>
          <p>
            <b>Speaker / Headphone issues:</b>
            <br />
            <br />First thing check all your volume levels.<br />
            <br />
            - In world volume is turned up. (Click the `...Menu` at the bottom right of the screen. then Click
            Preferences. Under the Audio properties, Make sure the `Voice Volume` is turned up)<br />
            <br />
            - Confirm laptop master volume is turned up<br />
            <br />
            - Confirm appliction volume is turned up<br />
            <br />
            - Confirm Headsphone / speakers volume is turned up<br />
            <br />
            - Confirm speakers / headphones are turned on and are charged or plugged in if requiring power<br />
            <br />
            - Confirm laptop audio drivers are upto date.<br />
            <br />
            If all else fails, Try refreshing your browser
          </p>
          <h5>How can I change my avatar?</h5>
          <h6>There are two places to change your Avatar.</h6>
          <p>
            (1) On the room loading screen. Before pressing enter click the `Change Avatar` button. It will bring you to
            a screen that you can select a public avatar to wear or you can upload your own
          </p>
          <p>
            (2) Within a NEAR Hub room. Click `...Menu`, then `Change name and Avatar`. click the `Change Avatar`
            button. It will bring you to a screen that you can select a public avatar to wear or you can upload your own
          </p>
        </Column>
      </Container>
    </PageContainer>
  );
}
