import { useState, useEffect, createContext } from "react";
import PropTypes from "prop-types";
import * as nearAPI from "near-api-js";
import Big from 'big.js';

import { fetchRandomDefaultAvatarId, generateRandomName } from "../../utils/identity.js";

export const CONTRACT_NAME = process.env.CONTRACT_NAME || "guestbook.nearhubonline.near" /*"guestbook.nearhubonline.testnet"*/; 

export function getNearConfig(env) {
  switch (env) {
    case "production":
    case "mainnet":
      return {
        networkId: "mainnet",
        nodeUrl: "https://rpc.mainnet.near.org",
        contractName: "guestbook.nearhubonline.near",
        walletUrl: "https://app.mynearwallet.com",
        helperUrl: "https://helper.mainnet.near.org"
      };
    // This is an example app so production is set to testnet.
    // You can move production to mainnet if that is applicable.
    case "development":
    case "testnet":
      return {
        networkId: "testnet",
        nodeUrl: "https://rpc.testnet.near.org",
        contractName: "guestbook.nearhubonline.testnet",
        walletUrl: "https://wallet.testnet.near.org",
        helperUrl: "https://helper.testnet.near.org"
      };
    case "betanet":
      return {
        networkId: "betanet",
        nodeUrl: "https://rpc.betanet.near.org",
        contractName: CONTRACT_NAME,
        walletUrl: "https://wallet.betanet.near.org",
        helperUrl: "https://helper.betanet.near.org"
      };
    case "local":
      return {
        networkId: "local",
        nodeUrl: "http://localhost:3030",
        keyPath: `${process.env.HOME}/.near/validator_key.json`,
        walletUrl: "http://localhost:4000/wallet",
        contractName: CONTRACT_NAME
      };
    case "test":
    case "ci":
      return {
        networkId: "shared-test",
        nodeUrl: "https://rpc.ci-testnet.near.org",
        contractName: CONTRACT_NAME,
        masterAccount: "test.near"
      };
    case "ci-betanet":
      return {
        networkId: "shared-test-staging",
        nodeUrl: "https://rpc.ci-betanet.near.org",
        contractName: CONTRACT_NAME,
        masterAccount: "test.near"
      };
    default:
      throw Error(`Unconfigured environment '${env}'. Can be configured in src/config.js.`);
  }
}

const WalletContext = createContext();
export function WalletProvider({ store, children }) {

  const [wallet, setWallet] = useState(store?.state?.credentials?.wallet);
  useEffect(
    () => {
      const nearConfig = getNearConfig(/*process.env.NODE_ENV ||*/ "mainnet");
      nearAPI
        .connect(Object.assign({ deps: { keyStore: new nearAPI.keyStores.BrowserLocalStorageKeyStore() } }, nearConfig))
        .then(near => {
          window.nearAPI = nearAPI;
          window.nearConfig = nearConfig;
          window.nearWallet = new nearAPI.WalletAccount(near);
                    
          const contract = new nearAPI.Contract(
            window.nearWallet.account(),
            nearConfig.contractName,
            {
              viewMethods: ['getMessages', 'getMessagesId'],
              changeMethods: ['addMessage', 'addMessageId'],
              sender: window.nearWallet.getAccountId(),
            }
          );

          window.nearContract = contract;

          setWallet(window.nearWallet);
          const walletID = window.nearWallet.getAccountId() ? window.nearWallet.getAccountId() : null;
          if (walletID) {
            store.update({
              credentials: { ...store.state.credentials, wallet: walletID },
              profile: { ...store.state.profile, displayName: walletID }
            });
          } else {
            const qs = new URLSearchParams(location.search);
            // Support legacy sign in urls.
            if (qs.has("wallet_sign_in")) {
              window.nearWallet.requestSignIn(window.nearConfig.contractName, "Nearhub");
            } else {
              fetchRandomDefaultAvatarId().then(ava => {
                store.update({
                  profile: { avatarId: ava, displayName: generateRandomName() }
                });              
              });
            }
          }
        });
    },
    [setWallet, store]
  );

  return children;
}

WalletProvider.propTypes = {
  store: PropTypes.object,
  children: PropTypes.node
};
