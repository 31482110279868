import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styles from "./SignInButton.scss";
import { ConnectButton } from "../input/Button";

export function SignInButton({ mobile }) {
  const onConnect = useCallback(e => {
    e.preventDefault();
    window.nearWallet.requestSignIn(window.nearConfig.contractName, "Nearhub");
  }, []);

  return (
    <ConnectButton
      className={mobile ? styles.mobileSignIn : styles.SignInButton}
      thick
      preset="signin"
      as="a"
      href="/connect"
      onClick={onConnect}
    >
      <FormattedMessage id="sign-in-button" defaultMessage="Connect Wallet" />
    </ConnectButton>
  );
}

SignInButton.propTypes = {
  mobile: PropTypes.bool
};