import React, { createContext, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import configs from "../../utils/configs";

import { PageContainer } from "../layout/PageContainer";
import { Center } from "../layout/Center";
import { VerifyModal, VerifyingNear } from "./VerifyModal";

// TODO: We really shouldn't include these dependencies on every page. A dynamic import would work better.
import jwtDecode from "jwt-decode";
import AuthChannel from "../../utils/auth-channel";
import { connectToReticulum } from "../../utils/phoenix-utils";

export const AuthContext = createContext();

async function checkIsAdmin(socket, store) {
  // TODO: Doing all of this just to determine if the user is an admin seems unnecessary. The auth callback should have the isAdmin flag.
  const retPhxChannel = socket.channel("ret", { hub_id: "index", token: store.state.credentials.token });

  const perms = await new Promise(resolve => {
    retPhxChannel
      .join()
      .receive("ok", () => {
        retPhxChannel
          .push("refresh_perms_token")
          .receive("ok", ({ perms_token }) => {
            const perms = jwtDecode(perms_token);
            retPhxChannel.leave();
            resolve(perms);
          })
          .receive("error", error => {
            console.error("Error sending refresh_perms_token message", error);
          })
          .receive("timeout", () => {
            console.error("Sending refresh_perms_token timed out");
          });
      })
      .receive("error", error => {
        console.error("Error joining Phoenix Channel", error);
      })
      .receive("timeout", () => {
        console.error("Phoenix Channel join timed out");
      });
  });

  const isAdmin = perms.postgrest_role === "ret_admin";

  configs.setIsAdmin(isAdmin);

  return isAdmin;
}

const noop = () => {};

export function StorybookAuthContextProvider({ children }) {
  const [context] = useState({
    initialized: true,
    isSignedIn: true,
    isAdmin: true,
    token: "abc123",
    email: "foo@bar.baz",
    userId: "00000000",
    signIn: noop,
    verify: noop,
    signOut: noop
  });
  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
}

StorybookAuthContextProvider.propTypes = {
  children: PropTypes.node,
  store: PropTypes.object.isRequired
};

export function AuthContextProvider({ children, store }) {
  const signIn = useCallback(
    async email => {
      const authChannel = new AuthChannel(store);
      const socket = await connectToReticulum();
      authChannel.setSocket(socket);
      const { authComplete } = await authChannel.startAuthentication(email);
      await authComplete;
      await checkIsAdmin(socket, store);
    },
    [store]
  );

  const verify = useCallback(
    async authParams => {
      const authChannel = new AuthChannel(store);
      const socket = await connectToReticulum();
      authChannel.setSocket(socket);
      await authChannel.verifyAuthentication(authParams.topic, authParams.token, authParams.payload);
    },
    [store]
  );

  const signOut = useCallback(
    async () => {
      configs.setIsAdmin(false);
      window.nearWallet.signOut();
      store.update({ 
        credentials: { 
          token: null, 
          email: null, 
          wallet: null 
        }, 
        profile: {} 
      });
      await store.resetToRandomDefaultAvatar();
    },
    [store]
  );

  const [context, setContext] = useState({
    initialized: false,
    isSignedIn: !!store.state.credentials && !!store.state.credentials.token,
    isAdmin: configs.isAdmin(),
    email: store.state.credentials && store.state.credentials.email,
    wallet: store.state.credentials && store.state.credentials.wallet,
    userId: store.credentialsAccountId,
    signIn,
    verify,
    signOut
  });

  let apiTimeout = null;
  const fetchAPIData = () => {
    fetch(
      "https://nearhub-auth-kk394.ondigitalocean.app/check/" +
        (context.wallet ? context.wallet : window.nearWallet.getAccountId()),
      { crossDomain: true }
    )
      .then(res => {
        if (res.status == 404) {
          apiTimeout = setTimeout(fetchAPIData, 500);
        } else if (res.status == 200) {
          clearTimeout(apiTimeout);
          res.json().then(function(res) {
            const authParams = {
              topic: res.auth_topic,
              token: res.auth_token,
              origin: res.auth_origin,
              payload: res.auth_payload
            };
            verify(authParams);
          });
        } else {
          clearTimeout(apiTimeout);
        }
      })
      .catch(function() {
        clearTimeout(apiTimeout);
      });
  };

  // Trigger re-renders when the store updates
  useEffect(
    () => {
      const onStoreChanged = () => {
      	
      	if (
          store.state.credentials.wallet &&
          store.state.credentials.email != store.state.credentials.wallet + "-auth@parse.nearhub.club"
        ) {
          /*
          if (!window.location.href.includes("/verify")) {
            const redirectUrl = new URL("/verify", window.location);
            window.location = redirectUrl;
          } else {
            */

          signIn(store.state.credentials.wallet + "-auth@parse.nearhub.club");
          apiTimeout = setTimeout(fetchAPIData, 1000);
          /*
          }
          */
        }
      	
        setContext(state => ({
          ...state,
          isSignedIn: !!store.state.credentials && !!store.state.credentials.token,
          isAdmin: configs.isAdmin(),
          email: store.state.credentials && store.state.credentials.email,
          wallet: store.state.credentials && store.state.credentials.wallet,
          userId: store.credentialsAccountId
        }));
      };

      store.addEventListener("statechanged", onStoreChanged);

      // Check if the user is an admin on page load
      const runAsync = async () => {
        if (store.state.credentials && store.state.credentials.token) {
          const socket = await connectToReticulum();
          return checkIsAdmin(socket, store);
        }

        return false;
      };

      runAsync()
        .then(isAdmin => {
          setContext(state => ({ ...state, isAdmin }));
        })
        .catch(error => {
          console.error(error);
          setContext(state => ({ ...state, isAdmin: false }));
        });

      return () => {
        store.removeEventListener("statechanged", onStoreChanged);
      };
    },
    [store, setContext, signIn, apiTimeout]
  );

  if (
    store.state.credentials.wallet &&
    store.state.credentials.email != store.state.credentials.wallet + "-auth@parse.nearhub.club" &&
    !window.location.href.includes("/verify") &&
    !window.location.href.includes("auth_payload=")
  ) {
    return (
      <AuthContext.Provider value={context}>
        <Center>
          <VerifyingNear />
        </Center>
      </AuthContext.Provider>
    );
  } else if(!store.state.credentials.wallet && window.location.href.includes("wallet_sign_in")) {
    return (
      <AuthContext.Provider value={context}>
        <Center>
          <VerifyingNear />
        </Center>
      </AuthContext.Provider>
    );
  } else if (store.state.credentials.wallet && window.location.href.includes("wallet_sign_in")) {
    const qs = new URLSearchParams(location.search);
    const redirectUrl = qs.get("sign_in_destination_url") || "/";
    window.location = redirectUrl;
    return (
      <AuthContext.Provider value={context}>
        <Center>
          <VerifyingNear />
        </Center>
      </AuthContext.Provider>
    );
  } else {
    return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
  }
}

AuthContextProvider.propTypes = {
  children: PropTypes.node,
  store: PropTypes.object.isRequired
};
